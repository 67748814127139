<template>
  <div class="post-wrap">
    <v-layout row>
      <v-flex sm12 md12 lg1>
        <v-btn flat class="blue-grey ma-0" @click="$router.go(-1)">
          <v-icon left dark class="font-size-20">
            arrow_back
          </v-icon>
          Back
        </v-btn>
      </v-flex>
      <v-flex sm12 md12 lg11>
        <v-card v-if="!!post && Object.keys(post).length > 0" class="pa-3 post-container">
          <div class="posts-list-item">
            <validation-observer ref="validationObserver">
              <v-form ref="form" @submit.prevent="submit()">
                <template v-if="post.channel">
                  <div v-if="showChannel" class="headline mb-3">
                    <div class="label mr-3">
                      Channel:
                    </div>
                    {{ post.channel.name }}
                  </div>
                  <div v-else class="headline mb-3">
                    <div class="label mr-3">
                      Link:
                    </div>
                    <a :href="post.channel.link" class=" break" target="_blank">
                      {{ post.channel.link }}
                    </a>
                  </div>
                  <!--                  <component-->
                  <!--                    :is="showChannel ? 'div' : 'a'"-->
                  <!--                    :href="showChannel ? false : post.channel.link"-->
                  <!--                    :target="showChannel ? false : '_blank'"-->
                  <!--                    class=" break"-->
                  <!--                  >-->
                  <!--                    {{ showChannel ? post.channel.name : post.channel.link }}-->
                  <!--                  </component>-->
                </template>
                <div class="mb-2">
                  <div class="label mb-1">
                    Title:
                  </div>
                  <div>
                    <validation-text-area
                      v-model.trim="post.title"
                      class="l-grey"
                      label="No data"
                      type="text"
                      :success="false"
                      name="name"
                      :rules="{ required:true,max:255}"
                      counter="255"
                      solo
                      box
                      rows="4"
                      required
                      :error-messages="errors.collect('title')"
                    />
                  </div>
                </div>
                <div class="mb-2">
                  <div class="label mb-1">
                    Description:
                  </div>
                  <div>
                    <validation-text-area
                      v-model.trim="post.description"
                      class="l-grey"
                      label="No data"
                      type="text"
                      :success="false"
                      name="name"
                      :rules="{ max:60000, required: requiredDesc}"
                      counter="60000"
                      solo
                      box
                      rows="6"
                      required
                      :error-messages="errors.collect('description')"
                    />
                  </div>
                </div>
                <div class="mb-2">
                  <v-layout align-center justify-start row>
                    <v-flex xs6>
                      <v-checkbox
                        v-model="post.nsfw"
                        label="Sensitive Content"
                        class="checkbox-item ma-0 pa-0"
                        hide-details
                      />
                    </v-flex>
                    <v-flex xs6>
                      <v-checkbox
                        v-model="isTranslatable"
                        label="Make translate"
                        class="checkbox-item ma-0 pa-0"
                        hide-details
                      />
                    </v-flex>
                    <v-flex xs6 class="text-xs-right">
                      {{ timePassed(post.date) }}
                    </v-flex>
                  </v-layout>
                </div>
                <div class="mb-2">
                  <v-layout justify-space-between row>
                    <v-flex xs5>
                      <div class="label mb-1">
                        Tags limit is 6, Character limit is 30
                      </div>
                      <div class="post-select">
                        <v-combobox
                          ref="combobox"
                          v-model="selectedTags"
                          :filter="filterItems"
                          :loading="loading"
                          :items="tagsDefaultList"
                          :search-input.sync="search"
                          :menu-props="{
                            contentClass:'add-select-tag-drop elevation-0',
                            value: autoSelectMenu,
                          }"
                          :error="tagError"
                          :disabled="disabledAddTag"
                          :rules="[required]"
                          no-filter
                          append-icon="mdi-plus"
                          label="Add tag"
                          multiple
                          chips
                          solo
                          box
                          hide-selected
                          class="add-select-tag outlined-select bg-select"
                          @blur="blurInputTag()"
                          @input="addInputTag()"
                          @click:append="addButtonTag()"
                        >
                          <template v-slot:no-data>
                            <div class="no-tag">
                              There are no tags in your avoid list
                            </div>
                          </template>
                        </v-combobox>
                        <!--                        <span v-if="tagError" class="tag-hint tag-hint-error">-->
                        <!--                          The tags field is required.-->
                        <!--                        </span>-->
                      </div>
                    </v-flex>
                    <v-flex xs7>
                      <div class="post-tags-list">
                        <ul class="tags-list">
                          <li
                            v-for="(tag, tagIndex) in selectedTags"
                            :key="`tag-${tag.id}-${tagIndex}`"
                            class="tags-item"
                          >
                            <div class="tag">
                              <span class="tag-name" v-text="tag"></span>
                              <v-btn
                                small
                                text
                                icon
                                class="btn-close"
                                @click="deleteTag(tag)"
                              >
                                <v-icon>mdi-close-circle-outline</v-icon>
                              </v-btn>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </v-flex>
                  </v-layout>
                </div>
                <template v-if="post.contents && post.contents.length">
                  <div v-for="(item, index) in post.contents" :key="index" class="content-item mt-3 pt-3">
                    <template v-if="item.type === 'image' || item.type === 'gif'">
                      <div class="content-block mb-2">
                        <div class="text-right">
                          <v-btn
                            color="error"
                            class="ma-0 mb-1"
                            @click="deleteContent(index)"
                          >
                            <span>Delete</span>
                          </v-btn>
                        </div>
                        <div>
                          <img :src="item.url" :alt="post.title">
                        </div>
                      </div>
                    </template>
                    <template v-if="item.type === 'video'">
                      <div class="content-block mb-2">
                        <div class="text-right">
                          <v-btn
                            color="error"
                            class="ma-0 mb-1"
                            @click="deleteContent(index)"
                          >
                            <span>Delete</span>
                          </v-btn>
                        </div>
                        <div>
                          <video
                            controls
                            :src="item.url"
                            type="video/mp4"
                          >
                          </video>
                        </div>
                      </div>
                    </template>
                    <template v-if="item.type === 'iframe'">
                      <div class="content-block content-iframe mb-2">
                        <div class="text-right">
                          <v-btn
                            color="error"
                            class="ma-0 mb-1"
                            @click="deleteContent(index)"
                          >
                            <span>Delete</span>
                          </v-btn>
                        </div>
                        <div class="iframe-block">
                          <iframe
                            :src="
                              `https://www.youtube.com/embed/${youtubeUrl(item.url)}`
                            "
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
                <div class="content-item btn-block mt-3 pt-3">
                  <v-btn
                    :loading="isLoading"
                    :disabled="disableSubmit"
                    color="success"
                    class="ma-0 ml-2"
                    type="submit"
                  >
                    <span>Save and publish</span>
                  </v-btn>
                  <v-btn
                    v-if="post.status === 'waiting'"
                    :disabled="isLoading"
                    color="warning"
                    class="ma-0 ml-2"
                    @click="openDeclineModal"
                  >
                    <span>Decline</span>
                  </v-btn>
                  <v-btn v-else flat class="blue-grey ma-0  ml-2" @click="$router.go(-1)">
                    <v-icon left dark class="font-size-20">
                      arrow_back
                    </v-icon>
                    Back
                  </v-btn>
                  <v-btn
                    :disabled="isLoading"
                    color="error"
                    class="ma-0 ml-2"
                    @click="deletePost(post.id)"
                  >
                    <span>Delete forever</span>
                  </v-btn>
                </div>
              </v-form>
            </validation-observer>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
    <decline-parsed-post-modal
      v-model="declineModal"
      :item="post"
    />
    <accept-modal
      v-model="showAcceptModal"
      :post-id="post.id"
      :post-type="$route.params.name"
      @accept="deleteForeverPost"
    />
  </div>
</template>

<script>
import { axios } from "../../utils/axios"
import { apiUrl } from "../../config"
import {createParsedPostRequest} from '../../api/parsedPost'
import moment from "moment"
import DeclineParsedPostModal from "./DeclineParsedPostModal"
import mixinYoutubeUrl from "../../mixins/mixinYoutubeUrl"
import AcceptModal from "@/components/Modals/AcceptModal"
import { deleteParsedForeverPost } from "@/api/post"

export default {
  name: "CreateParsedPost",
  components: {
    DeclineParsedPostModal,
    AcceptModal,
  },
  mixins: [mixinYoutubeUrl],
  props: {
    post: {
      type: Object,
      default: () => {}
    },
    languages: {
      type: Array,
      default: () => []
    },
    supportedLanguages: {
      type: Array,
      default: () => []
    },
    getPostById: {
      type: Function,
      default: () => {},
      // default: null
    }
  },
  data() {
    return {
      isLoading: false,
      nsfw: false,
      isTranslatable: true,
      languagesErrors: 'Select language',
      selectedLang: null,
      disableSubmit: true,
      loading: false,
      selectedTags: [],
      tagsDefaultList: [],
      inputTagsLength: 6,
      inputTagsSymbolLength: 30,
      search: null,
      tagError: true,
      translateLanguages: [],
      requiredDesc: false,
      declineModal: false,
      showAcceptModal: false,
    }
  },
  computed: {
    autoSelectMenu () {
      return !!((this.search && this.search.length >= 2) &&
          (this.selectedTags && this.selectedTags.length < this.inputTagsLength))
    },
    disabledAddTag () {
      return !!(this.selectedTags && this.selectedTags.length >= this.inputTagsLength)
    },
    showChannel () {
      return this.$route.params.name === 'telegram'
    }
  },
  watch: {
    // selectedTags(val){
    //   if (val.length > 5) {
    //     this.$nextTick(() => this.selectedTags.pop())
    //   }
    // },
    search(newVal, oldVal){
      if (newVal !== oldVal){
        this.searchInput(newVal)
      }
    }
  },
  mounted() {
    this.selectedTags = this.post.tags
    this.$refs.combobox.validate() ? this.tagError = false: this.tagError = true
  },
  updated() {
    const contents = this.post.contents.map(item  => item.id)
    contents.length ? this.requiredDesc = false : this.requiredDesc = true
    this.validatePost()
  },
  methods:{
    openForeverDeleteModal(){
      this.showAcceptModal = true
    },
    async deletePost(postId) {
      let params = {
        post_id: postId,
        resource: this.$route.params.name
      }

      await deleteParsedForeverPost(params)
      this.deleteForeverPost()
    },
    deleteForeverPost(){
      this.$router.go(-1)
      this.$notificationShow(
          `The post is successfully deleted`
      )
    },
    async validatePost(){
      this.$refs.combobox.validate() ? this.tagError = false: this.tagError = true
      const valid = await this.$refs.validationObserver.validate()
      this.disableSubmit = !(valid && !this.tagError)
    },
    openDeclineModal(){
      this.declineModal = true
    },
    timePassed(create) {
      return moment(create).fromNow()
    },
    deleteContent(index) {
      this.post.contents.splice(index, 1)
    },
    async submit() {
      const valid = await this.$refs.validationObserver.validate()

      if (!valid || this.tagError) {
        return
      }

      this.errors.clear()

      const contents = this.post.contents.map(item  => item.id)
      let nsfw = this.post.nsfw ? 1 : 0
      let isTranslatable = this.isTranslatable ? 1 : 0
      let formData = new FormData()

      formData.append("post_id", this.post.id)
      formData.append("title", this.post.title.trim())

      if(this.post.description && this.post.description.length){
        formData.append("description", this.post.description)
      }

      formData.append("nsfw", `${nsfw}`)
      formData.append("is_translatable", `${isTranslatable}`)

      for (let i = 0; i < this.selectedTags.length; i++) {
        formData.append("tags[]", this.selectedTags[i])
      }

      for (let i = 0; i < contents.length; i++) {
        formData.append("contents[]", contents[i])
      }

      formData.append("resource", this.$route.params.name)

      this.isLoading = true

      await createParsedPostRequest(formData, [this.$route.params.name])
      .then(() => {
        this.$router.push({name: 'parser_need_approve_posts', params: {status:'published', name: this.$route.params.name}})
      })
      .catch(this.$notificationHandleResponseError)
      .finally(() => {this.isLoading = false})
    },
    required(value) {
      // if (value.length > 10) {
      //   this.selectedTag.pop()
      // }
      if (value instanceof Array && value.length == 0) {
        return "The tags field is required."
      }
      return !!value || "The tags field is required."
    },
    blurInputTag () {
      this.search = ''
    },
    searchInput () {
      if (this.search && this.search.length) {
        if (this.search.length >= this.inputTagsSymbolLength + 1) {
          this.$nextTick(() =>{
            this.search = this.search.slice(0, this.inputTagsSymbolLength)
          })
          // this.$refs.combobox.internalSearch = this.search.slice(0, this.inputTagsSymbolLength)
        }
        const regexps = /  +/g
        const results = regexps.test(this.search)
        if (results) {
          this.$nextTick(() =>{
            this.search = this.search.slice(0, -1)
          })
          // this.$refs.combobox.internalSearch = this.search.slice(0, -1)
        }
      }
      if (this.search && this.search.length > 1) {
        clearTimeout(this.inputSearchTimer)
        this.inputSearchTimer = setTimeout(() => {
          if (this.isLoading) { return }
          this.loading = true
          axios.get(`${apiUrl}tags?search=${this.search}`)
              .then((res) => {
                this.tagsDefaultList = res.data.data.map((tag) => {
                  return tag.title
                })
              })
              .catch((e) => {
                console.log(e)
              })
              .finally(() => {
                this.loading = false
                this.$nextTick(() => this.$refs.combobox.updateMenuDimensions())
              })
        }, 1000)
      }
    },
    unique (arr) {
      const result = []
      for (const str of arr) {
        if (!result.includes(str) && str.length) {
          result.push(str)
        }
      }
      return result
    },
    addInputTag () {
      this.tagError = false
      if (this.search && this.search.length && typeof this.search === 'string') {
        const index = this.selectedTags.indexOf(this.search)
        const str = this.search.trim()
        this.selectedTags[index] = str
        this.selectedTags = this.unique(this.selectedTags)
        if (this.selectedTags.length >= this.inputTagsLength) {
          this.search = null
          setTimeout(() => {
            this.$refs.combobox.isFocused = false
          }, 0)
        }
      }
    },
    addButtonTag () {
      if (this.$refs.combobox.internalSearch && this.$refs.combobox.internalSearch.length) {
        this.search = this.$refs.combobox.internalSearch.trim()
      }
      if (this.selectedTags.includes(this.search)) { this.search = null }
      if (
          !this.selectedTags.includes(this.search) &&
          this.search !== '' &&
          this.search &&
          this.search.length <= this.inputTagsSymbolLength &&
          typeof this.search === 'string'
      ) {
        this.selectedTags.push(this.search)
        this.tagError = false
        this.search = null
      }
      if (this.selectedTags.length >= this.inputTagsLength) {
        setTimeout(() => {
          this.$refs.combobox.isFocused = false
        }, 0)
      }
    },
    deleteTag (tag) {
      this.selectedTags.includes(tag)
          ? this.selectedTags.splice(this.selectedTags.indexOf(tag), 1)
          : this.selectedTags.push(tag)
    }
  }
}
</script>

<style scoped>
>>>.v-list__tile__title{
  font-size: 14px;
}
>>> .v-input--selection-controls:not(.v-input--hide-details) .v-input__slot{
  margin-bottom: 0;
}
.theme--dark >>> .v-btn.v-btn--disabled:not(.v-btn--icon):not(.v-btn--flat){
  font-size: 14px;
}
>>> .v-input.error--text .v-label{
color: red;
}
.content-item{
  display: flex;
  border-top: 1px solid #969696;
}
.content-item.btn-block{
  justify-content: flex-end;
}
.content-block{
  text-align: center;
  margin: 0 auto;
}
.content-block img, .content-block video{
  max-height: 68vh;
}
.text-right{
  text-align: right;
}
.post-container{
  max-width: 1200px;
  /*margin: 0 auto;*/
}
.post-wrap{
  position: relative;
}
.label{
  font-size: 16px;
}
.headline{
  display: inline-flex;
  align-items: baseline;
}

.lang-wrap .label{
  text-align: left;
}
.iframe-block iframe{
  pointer-events: auto;
  min-height: 560px;
}
.content-block.content-iframe{
  width: 100%;
}
</style>
