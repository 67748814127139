<template>
  <div>
    <v-dialog :value="show" max-width="500px" @input="close()">
      <v-card class="modal-body">
        <v-layout align-center justify-end>
          <div class="btn-close">
            <v-btn icon dark class="ma-0" @click.native="close">
              <v-icon>close</v-icon>
            </v-btn>
          </div>
        </v-layout>

        <v-card-text>
          <span class="modal-text break">
            Do you really want to decline this post?
          </span>
        </v-card-text>

        <v-card-actions class="px-3 pt-3 pb-4">
          <v-spacer />
          <v-btn color="error" @click.native="close">
            No
          </v-btn>
          <v-btn color="success" :loading="isLoading" @click.native="declinePost()">
            Yes, confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {declineParsedPost} from '../../api/parsedPost'

export default {
  name: "DeclineParsedPostModal",
  props: {
    value: Boolean,
    item: {
      type: [Object],
      default: () => {}
    },
    refresh: {
      type: [Function],
      default: () => {}
    }
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    }
  },
  methods: {
    close() {
      this.show = false
    },
    async declinePost() {
      this.isLoading = true
      await declineParsedPost({post_id: this.item.id, resource: this.$route.params.name})
          .then(() => {
            this.$router.push({name: 'parser_need_approve_posts', params: {status:'declined', name: this.$route.params.name}})
            this.show = false
          })
          .catch(this.$notificationHandleResponseError)
          .finally(() => {this.isLoading = false})
    }
  }
}
</script>
<style scoped>
.modal-text{
  font-size: 16px;
}
</style>
