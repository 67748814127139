<template>
  <div>
    <v-card v-if="isLoading" class="pa-3">
      <v-progress-linear
        :active="isLoading"
        :indeterminate="isLoading"
        :height="1"
        class="pa-0 ma-0"
      />
    </v-card>
    <post-create
      v-if="!isLoading && !!post"
      :post="post"
      :languages="languages"
      :supported-languages="supported_languages"
      :get-post-by-id="getPostById"
      :prev-route="prevRoute"
    />
  </div>
</template>

<script>
import PostCreate from '../../../../components/Parser/PostCreate'
import { getCreateParsedPostRequest } from "../../../../api/parsedPost"
// import {mapActions, mapGetters} from "vuex"

export default {
  name: "CreateParsedPostPage",
  components: {
    PostCreate
  },
  data() {
    return {
      post: {},
      supported_languages: [],
      languages: [],
      isLoading: true,
      prevRoute: null,
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  computed: {
    // ...mapGetters({
    //   languages: "languages"
    // }),
  },
  created() {
    this.getPostById()
  },
  methods: {
    // ...mapActions(["getAllLanguages"]),
    // async fetchLanguages() {
    //   if (!this.languages){
    //     await this.$store.dispatch("getAllLanguages")
    //   }
    // },
    async getPostById(){
      this.isLoading = true
      const params = {
        post_id: this.$route.params.id,
        resource: this.$route.params.name
      }
      getCreateParsedPostRequest(params)
          .then((res)=>{
            // await this.fetchLanguages()
            this.post = res.data.data
            this.supported_languages = res.data.supported_languages
            this.languages = res.data.languages
          })
          .catch(this.$notificationHandleResponseError)
          .finally(()=>{this.isLoading = false})
    }
  },
}
</script>